import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"

// Lazy load the Policy component using @loadable/component
const Policy = loadable(() =>
  import("../components/Contract/Policy/Content.jsx")
)

export default function PolicyPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/privacy-policy" />
      </Helmet>
      <Policy fallback={<div>Loading...</div>} />
    </Layout>
  )
}
